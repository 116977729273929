// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <video
          width="960"
          height="720"
          autoPlay
          muted
          onError={(e) => {
            console.error('Video failed to load:', e.target.error);
          }}
        >
          <source src="/EchoByldLP.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p>Coming Soon!</p>
    </div>
  );
}

export default App;
